<template>
  <div class="page-layout">
    <div
      ref="loginContainer"
      class="login-container"
    >
      <div class="logo-wrapper">
        <img
          class="logo"
          width="74"
          height="79"
          src="@/assets/img/logo.png"
          alt
        >
      </div>

      <form class="form-wrapper">
        <div
          class="input-field border-bottom-1px"
          :class="{ active: mobileFocus === 1 }"
        >
          <i class="iconfont icon-joyo">&#xe71f;</i>
          <cube-input
            v-model="mobileParams.value"
            class="cube-input-wrapper"
            :clearable="mobileParams.clearable"
            :placeholder="mobileParams.placeholder"
            :type="mobileParams.type"
            :maxlength="mobileParams.maxlength"
            :autocomplete="true"
            @focus="focusInput(1)"
            @blur="blurInput"
          />
        </div>
        <div
          class="input-field border-bottom-1px"
          :class="{ active: mobileFocus === 2 }"
        >
          <i class="iconfont icon-joyo">&#xe721;</i>
          <cube-input
            v-model="passParams.value"
            class="cube-input-wrapper"
            :clearable="passParams.clearable"
            :placeholder="passParams.placeholder"
            :type="passParams.type"
            :maxlength="passParams.maxlength"
            :autocomplete="true"
            :eye="passParams.eye"
            @focus="focusInput(2)"
            @blur="blurInput"
          />
        </div>
        <div
          v-if="showMsgVerify === true"
          class="msg-field"
        >
          <div
            class="input-field border-bottom-1px msg"
            :class="{ active: mobileFocus === 3 }"
          >
            <i class="iconfont icon-joyo">&#xe72a;</i>
            <cube-input
              v-model="messageParams.value"
              class="cube-input-wrapper"
              :clearable="messageParams.clearable"
              :placeholder="messageParams.placeholder"
              :type="messageParams.type"
              :autocomplete="true"
              @focus="focusInput(3)"
              @blur="blurInput"
            />
          </div>
          <img
            :src="certPic"
            class="msg-code"
            @click="refreshPic"
          >
        </div>
      </form>
      <div class="login-btn">
        <cube-button
          :primary="true"
          :disabled="loginBtnDisable"
          class="button"
          @click="verifyLogin"
        >
          {{ loginBtnText }}
        </cube-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { getAccessToken } from '_lib/common';
import md5 from 'blueimp-md5';
import config from '_lib/config';
import { login, getCertPic, loginFinish } from '../../api/sign-in';
import { emailReg, jobNoReg, phoneReg, passReg } from '../../lib/validate';

export default {
  name: 'SignIn',
  data() {
    return {
      mobileParams: {
        value: '',
        placeholder: '手机号码/工号/邮箱',
        type: 'text',
        clearable: {
          visible: true,
          blurHidden: false
        }
      },
      passParams: {
        value: '',
        placeholder: '密码',
        type: 'password',
        eye: {
          open: false,
          reverse: false
        },
        clearable: {
          visible: true,
          blurHidden: false
        }
      },
      messageParams: {
        value: '',
        placeholder: '短信验证码',
        type: 'text',
        clearable: false,
        certPic: ''
      },
      mobileFocus: 0,
      showPassword: true,
      showMsgVerify: false,
      loginBtnDisable: false,
      orgId: '',
      postId: ''
    };
  },
  computed: {
    certPic() {
      return `data:image/jpg;base64,${this.messageParams.certPic}`;
    },
    loginBtnText() {
      return this.loginBtnDisable ? '登录中' : '登录';
    },
    ...mapGetters(['orgAndPostList'])
  },
  created() {
    getAccessToken();
    // console.log(this.$route.meta)
  },

  methods: {
    focusInput(n) {
      this.mobileFocus = n;
    },

    blurInput() {
      this.mobileFocus = 0;
      setTimeout(() => {
        if (this.mobileFocus === 0) {
          window.scrollTo(0, 0);
        }
      }, 0);
    },

    refreshPic() {
      this._getCertPic();
    },

    verifyLogin() {
      const { value } = this.mobileParams;
      if (value.trim() === '') {
        return this.$showToast('请输入手机号码/工号/邮箱');
      } else if (!phoneReg(value) && !jobNoReg(value) && !emailReg(value)) {
        if (typeof value === 'number' && !isNaN(value)) {
          if (!phoneReg(value)) {
            return this.$showToast('请输入正确的手机号码');
          }
        } else if (value.indexOf('@') !== -1) {
          if (!emailReg(value)) {
            return this.$showToast('请输入正确的邮箱');
          }
        } else {
          if (!jobNoReg(value)) {
            return this.$showToast('请输入正确的工号');
          }
        }
      }

      const pass = this.passParams.value;
      if (pass.trim() === '') {
        return this.$showToast('请输入密码');
      } else if (!passReg(pass)) {
        return this.$showToast('请输入正确的密码');
      }

      if (this.showMsgVerify) {
        if (this.messageParams.value.trim() === '') {
          return this.$showToast('请输入图形验证码');
        }
      }

      this.loginBtnDisable = true;
      this._login();
    },

    handleLogin() {
      localStorage.setItem(
        'USERNAME',
        btoa(
          JSON.stringify({
            loginSource: 0
          })
        )
      );
      // if (this.orgAndPostList.length > 1) {
      //   this.$router.push({ name: 'select-identity' });
      // } else if (this.orgAndPostList.length === 1) {
      // const [item] = this.orgAndPostList;
      loginFinish(this.orgId, this.postId, 0).then(res => {
        if (res.flag) {
          this.setUserInfo(res.data);
          this.setToken(res.data.token);
          this.$router.push({ name: 'home' });
        } else {
          let data = {
            account: res.data.userAccount,
            pwd: res.data.passWord,
            tenancyId: res.data.tenancyId,
            deptId: res.data.deptId,
            postId: res.data.postId,
            loginSource: 0
          };
          localStorage.setItem('USERNAME', btoa(JSON.stringify(data)));
          if (res.errorCode === config.ERR_SIGN_FIRST_RESET) {
            // 首次登录未修改密码
            this.$router.push({ name: 'first-reset' });
          } else if (res.errorCode === config.ERR_SIGN_TIME_OUT) {
            // 距离上次登陆超过72小时
            this.$router.push({ name: 'authentication' });
          } else {
            this.$showToast(res.errorMsg);
          }
        }
      });
      // }
    },

    _getCertPic() {
      getCertPic().then(res => {
        if (res.flag) {
          this.messageParams.certPic = res.data;
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },

    setUsername(res) {
      localStorage.setItem(
        'USERNAME',
        btoa(
          JSON.stringify({
            account: res.data.userAccount,
            pwd: res.data.passWord,
            tenancyId: res.data.tenancyId,
            deptId: res.data.deptId,
            postId: res.data.postId,
            loginSource: 0
          })
        )
      );
    },

    _login() {
      window.scrollTo(0, 0);
      const pass = md5(this.passParams.value);
      login(this.mobileParams.value, pass, this.messageParams.value, 0)
        .then(res => {
          this.loginBtnDisable = false;

          if (res.flag) {
            this.$showToast('登录成功');
            this.setToken(res.data.token);
            this.setOrgAndPostList(res.data.postRoleResList);
            this.setUserInfo(res.data);
            this.orgId = res.data.orgId;
            this.postId = res.data.postId;
            localStorage.setItem(
              'USERNAME',
              btoa(
                JSON.stringify({
                  loginSource: 0
                })
              )
            );
            // 登录完成判断来源
            if (this.$route.query.redirectUrl) {
              this.$router.push({ path:this.$route.query.redirectUrl  })
            } else {
              this.$router.push({ name: 'home' });
            }
            // this.handleLogin();
          } else {
            this.messageParams.value = '';
            if (res.errorCode === config.ERR_FAIL_PASS) {
              this.showMsgVerify = true;
              this.$showToast(res.errorMsg);
              if (res.data >= 3) {
                this.showMsgVerify = true;
              }
            } else if (
              res.errorCode === config.ERR_NO_VCODE ||
              res.errorCode === config.ERR_FAIL_VCODE
            ) {
              this.showMsgVerify = true;
            } else if (res.errorCode === config.ERR_SIGN_FIRST_RESET) {
              // 首次登录未修改密码
              this.setUsername(res);
              this.$router.push({ name: 'first-reset' });
            } else if (res.errorCode === config.ERR_SIGN_TIME_OUT) {
              // 距离上次登陆超过72小时
              this.setUsername(res);
              this.$router.push({ name: 'authentication' });
            } else {
              this.$showToast(res.errorMsg);
            }
            this.showMsgVerify && this.refreshPic();
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.showMsgVerify && this.refreshPic();
          this.messageParams.value = '';
          this.loginBtnDisable = false;
        });
    },
    ...mapMutations({
      setUserInfo: 'SET_USER_INFO',
      setToken: 'SET_TOKEN',
      setOrgAndPostList: 'SET_ORG_AND_POST_LIST'
    })
  },

  mounted() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // 兼容 safari 的 100vh
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    } else if (/(Android)/i.test(navigator.userAgent)) {
      // 兼容安卓小键盘
      const height = localStorage.getItem('screenHeight') || window.innerHeight;
      this.$refs.loginContainer.style.height = `${height}px`;
      localStorage.setItem('screenHeight', height);
    }
  }
};
</script>

<style lang="scss" scoped>
.page-layout {
  height: 100%;
}

.login-container {
  height: 100%;
  background: #ffffff;
}

.logo-wrapper {
  padding: 58px 0 36px;

  .logo {
    display: block;
    width: 74px;
    height: 79px;
    margin: 0 auto;
  }
}

.form-wrapper {
  padding: 0 25px;

  .input-field {
    display: flex;
    align-items: center;
    padding: 11px 0 2px;
    animation: all 0.3s;

    .icon-joyo {
      color: #aaaaaa;
    }

    &.active {
      .icon-joyo {
        color: $color-joyo;
      }
    }

    input {
      flex: 1;
      margin: 0 14px;
    }

    .icon-input {
      width: 17px;
      height: 17px;
    }

    .input-append {
      &.close {
        width: 15px;
        height: 15px;
      }
    }
  }

  .msg-field {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .msg-code {
      width: 70px;
      height: 40px;
    }
  }
}

.login-btn {
  margin: 60px 25px 0;

  .button {
    background: $color-joyo;
  }
}

.icon-eye {
  width: 19px;
  height: 14px;
  margin-left: 10px;
}
</style>
